import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast";

// const baseUrl = "http://localhost:8080/api/v1/users";
const baseUrl = "https://rentspherebackend.annds.online/api/v1/users";
export const api = axios.create({
  baseURL: baseUrl,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <Toaster position="bottom-center" reverseOrder={false} />
  </Provider>
);
